import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { usePage } from "./usePage";
import { useSite } from "./useSite";

interface useAIReferenceFieldResponse {
	page: number;
	itemsPerPage: number;
	totalItems: number;
	items: Array<{
		title: string;
		url: string;
		image: null;
		description: string;
		template: string;
		similarity: number;
		structuredData?: Record<string, unknown>;
	}>;
}

type useAIReferenceFieldProps = {
	// Son opcionales porque en AX puedes no tenerlos rellenados o seleccionados.
	prompt?: string;
	templates?: Array<string>;
	sites?: Array<number>;
	language?: number;
	limit?: number;
	// Son completamente opcionales.
	useStructuredData?: boolean;
	fields?: Array<string>;
	area?: string;
};

/**
 * Hook to call the AI ReferenceField API.
 *
 * @example
 * const news = useAIReferenceField(data)
 * news.items.map(...)
 */
export function useAIReferenceField(
	data?: useAIReferenceFieldProps,
): useAIReferenceFieldResponse | undefined | null {
	const { publicApiUrl } = useSite();
	const { id: pageId } = usePage();
	const [response, setResponse] =
		React.useState<useAIReferenceFieldResponse | null>(null);
	const {
		prompt,
		templates,
		sites,
		language,
		useStructuredData = false,
		fields,
		limit,
		area,
	} = data || {};

	const [id] = React.useState(() => {
		if (typeof window === "undefined") return "";
		try {
			let storedId = localStorage.getItem("gpx_id");
			if (!storedId) {
				storedId = uuidv4();
				localStorage.setItem("gpx_id", storedId);
			}
			return storedId;
		} catch (error) {
			console.error("Error accessing localStorage:", error);
			return uuidv4();
		}
	});

	React.useEffect(() => {
		// no data
		if (!data) return;
		// no templates
		if (Array.isArray(data.templates) && data?.templates.length < 1) return;
		// no language
		if (!data.language) return;
		// no limit
		if (!data.limit) return;

		try {
			const url = `${publicApiUrl}/gpx/pages/`;

			const body = {
				id,
				prompt,
				templates,
				sites,
				language,
				useStructuredData,
				fields,
				limit,
				area,
				pageId,
			};

			fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
			})
				.then((res) => {
					if (!res.ok) {
						throw new Error(`HTTP error! status: ${res.status}`);
					}
					return res.json();
				})
				.then((data) => {
					return setResponse(data);
				})
				.catch((error) => {
					console.error("Error:", error);
					setResponse(null);
				});
		} catch (error) {
			console.error("Error", error);
			setResponse(null);
		}
	}, [
		publicApiUrl,
		data?.prompt,
		data?.templates,
		data?.sites,
		data?.language,
		data?.limit,
	]);

	return response;
}
